<template>
  <ml-container>
    <div v-if="promotion" class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-8">
      <div class="tw-text-sm mb-3 sm:tw-col-span-2">
        <span class="tw-text-red">Landing page</span> /
        {{ promotion.title }}
      </div>
      <div>
        <gallery :images="promotion.images" :index-gallery-setup="{'0': 'lg:tw-row-span-2 tw-col-span-4 tw-h-100'}" :gallery-setup="{}"  />
      </div>
      <div class="tw-text-black">
        <h1 class=" tw-text-2rem tw-font-semibold tw-leading-relaxed tw-mb-6">{{ promotion.title }}</h1>

        <div class="tw-leading-relaxed" v-html="promotion.description"></div>
        <a
            class="tw-inline-flex tw-mt-5 active:tw-bg-gray-n1 active:tw-border-black focus:tw-ring-black focus:tw-ring-2 focus:tw-border-black hover:tw-border-black tw-text-black tw-border-solid tw-border-2 tw-border-gray-n1 tw-bg-white tw-h-14 tw-no-underline tw-items-center tw-px-8 tw-py-4 tw-leading-4 tw-font-semibold tw-rounded-xl tw-inline-block tw-justify-center tw-gap-x-3 focus:tw-outline-none disabled:tw-pointer-events-none"
            :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(currentUrl)">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 12.5C24 5.86889 18.6311 0.5 12 0.5C5.36889 0.5 0 5.86889 0 12.5C0 18.4911 4.39111 23.4511 10.1333 24.3578V15.9667H7.07555V12.5H10.1333V9.85111C10.1333 6.84666 11.9111 5.19333 14.6489 5.19333C15.9644 5.19333 17.3333 5.42444 17.3333 5.42444V8.37555H15.8222C14.3289 8.37555 13.8667 9.3 13.8667 10.2422V12.5H17.1911L16.6578 15.9667H13.8667V24.3578C19.6089 23.4511 24 18.4911 24 12.5Z" fill="#4676ED"/>
            <path d="M16.6756 15.9667L17.2089 12.5H13.8667V10.2422C13.8667 9.30002 14.3289 8.37558 15.8222 8.37558H17.3333V5.42447C17.3333 5.42447 15.9644 5.19336 14.6489 5.19336C11.9111 5.19336 10.1333 6.84669 10.1333 9.85113V12.5H7.07556V15.9667H10.1333V24.3578C10.7378 24.4467 11.3778 24.5 12 24.5C12.64 24.5 13.2622 24.4467 13.8667 24.3578V15.9667H16.6756Z" fill="white"/>
          </svg>
          Share on Facebook
        </a>

      </div>
    </div>

  </ml-container>
</template>

<script>

import MlContainer from "@/components/layout/MlContainer.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: 'promotionDetail',
  components:{
    MlContainer,
    Gallery,

  },
  data() {
    return {
      promotion: null,
      images: [],
      currentUrl: null,
      openTerms: false,
    }
  },
  computed: {

  },
  mounted() {
    this.getPromotion();
    this.currentUrl = window.location.href;
  },
  methods: {
    getPromotion() {
      this.$http.get('/api/promotions/' + this.$route.params.id)
          .then(({data}) => {
            this.promotion = data.data;
          })
          .catch(error => {
            console.log(error)
          });

    },
  }
};
</script>


